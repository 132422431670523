<template>
  <Panel header="Login" id="login-painel">
    <div class="grid p-fluid">
      <div class="col-12 p-field">
        <label for="email" class="p-sr-only">E-mail</label>
        <InputText
          id="email"
          type="text"
          placeholder="E-mail"
          v-model="email"
        />
      </div>
      <div class="col-12 p-field">
        <label for="senha" class="p-sr-only">Senha</label>
        <InputText
          id="senha"
          type="password"
          placeholder="Senha"
          v-model="senha"
        />
      </div>
      <div class="col-12 p-field">
        <Button
          type="button"
          label="autenticar"
          icon="pi pi-check"
          @click="login"
        />
      </div>
    </div>
  </Panel>

  <Toast position="top-right" />

</template>

<script>

import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  data() {
    return {
      email: "",
      senha: "",
    };
  },
  methods: {
    login: function () {
        const self = this;

        firebase.auth().signInWithEmailAndPassword(self.email, self.senha)
        .then(() => {
            self.$router.push('/');
        })
        .catch((error) => {

          self.$toast.add({
            severity: "error",
            summary: "ERRO: " + error.code,
            detail: error.message,
            life: 5000,
          });

        });

    }
  },
};
</script>

<style scoped>
    #login-painel {
    width: 400px;
    position: absolute;
    left: 50%;
    margin-left: -200px;
    top: 50%;
    margin-top: -100px;
    }
</style>